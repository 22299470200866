.katalog-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .katalog-title {
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #333;
  }
  
  .katalog-categories {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .category-tile {
    width: 300px;
    height: 300px;
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s;
  }
  
  .category-tile:hover {
    transform: translateY(-10px);
  }
  
  .category-tile img {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
  }
  
  .category-tile h2 {
    font-size: 1.5rem;
    color: #555;
  }
  