.edit-main {
    position: fixed;
    bottom: 10vh;
    right: 1vw;
    width: 20vw;
    height: 40vh;
    background-color: #f8f9fa; 
    color: #343a40; 
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); 
    border-radius: 12px; 
    padding: 20px; 
    overflow-y: auto; 
}


.edit-main p {
  font-size: 18px;
  margin: 10px 0; 
  font-weight: 500; 
}

.edit-main .ant-slider, 
.edit-main .ant-input-number {
    width: 100%;
    max-width: 220px;
}


.edit-main button {
  width: 100%;
  max-width: 220px;
  padding: 10px;
  font-size: 16px;
  margin-top: 10px;
  border-radius: 8px;
}

.edit-main .ant-btn-danger {
  background-color: #dc3545;
  border: none;
}

.end-button-edit-sprinkler {
  background-color: #007bff;
  border: none;
}

@media (max-width: 1024px) {
  .edit-main {
      width: 20vw;
      bottom: 18vh;
      right: 2vw;
  }
}

@media (max-width: 768px) {
  .edit-main {
      width: 30vw;
      height: 25vh;
      bottom: 15vh;
      right: 2vw;
  }

  .edit-main p {
      font-size: 16px;
  }

  .edit-main .ant-input-number {
      width: 90%;
  }

  .edit-main button {
      max-width: 90%;
  }
}

@media (max-width: 480px) {
  .edit-main {
      width: 40vw;
      bottom: 16vh;
      right: 1vw;
      padding: 10px;
  }

  .edit-main p {
      font-size: 14px;
  }

  .edit-main .ant-input-number {
      width: 80%;
  }

  .edit-main button {
      max-width: 80%;
  }
}
