.project-page-main {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: row;
    
}

.project-stage {
    flex: 1;
    background-color: #ddd;
}


@media (max-width: 768px){
    .project-page-main{
        flex-direction: column;
    }
}