.active-section-main {
  position: fixed;
  top: 15vh;
  right: 1vw;
  width: 20vw; 
  height: 30vh;
  background-color: #ffffff;
  z-index: 2;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px; 
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); 
  overflow-y: auto; 
}

.active-section-main span {
  margin: 10px;
  font-size: 16px;
  padding: 5px;
  color: #343a40;
  word-break: break-word

}

.active-section-main p {
  margin: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #495057;
}

.active-section-delete-button{
  width: 95%;
  margin: 10px 0 10px 0;
}

.active-section-pipe-button{
  width: 95%;
  margin: 10px 0 10px 0;
}


@media (max-width: 1024px) {
  .active-section-main {
      width: 20vw;
      max-height: 20vh;
  }
}



@media (max-width: 768px) {
  .active-section-main {
      width: 30vw;
      height: 15vh;
      top: 42vh;
  }

  .active-section-main p,
  .active-section-main span {
      font-size: 14px;
  }

  .active-section-delete-button,
  .active-section-pipe-button {
      width: 90%;
  }
}

@media (max-width: 480px) {
  .active-section-main {
      top: 40vh; 
      max-height: 20vh;
      width: 40vw;
  }

  .active-section-main p,
  .active-section-main span {
      font-size: 12px;
  }

  .active-section-delete-button,
  .active-section-pipe-button {
      width: 90%;
  }
}
