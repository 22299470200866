.home-page-main-section {
    height: auto;
    width: 100%;
    padding: 5vh 5vh 5vh 5vh; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 50px;

}




.home-page-project-image img{
    height: 50vh;
    border-radius: 15%;
}


.home-page-description{
    margin-left: 50px;
    
}

.home-page-description h2 {
    margin-bottom: 30px;
    font-size: 48px;

}


.home-page-description p {
    font-size: 26px;
    color: #555;
}

@media (max-width: 1440px){
    .home-page-project-image img{
        height: 35vh;
    }
}

@media (max-width: 1024px) {
    .home-page-project-image img{
        height: 30vh;
    }
}

@media (max-width: 768px) {
    .home-page-main-section {
        height: auto;
        padding: 0;
    }
    .home-page-main-section {
        flex-direction: column;
    }
    .home-page-project-image img{
        height: 25vh;
        margin-bottom: 20px;
    }

    .home-page-description{
        margin-bottom: 100px;
        
    }

    .home-page-description h2 {

        font-size: 36px;
    
    }

    .home-page-description p {
        font-size: 18px;
    }
  }
  
  @media (max-width: 480px) {
    
  }



