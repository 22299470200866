.project-menu-list{
    height: 80vh;
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 1.5rem;
    position: relative;
    width: 300px;
    overflow-y: auto;
    overflow-x: hidden;


    
}


@media (max-width: 768px) {
    .project-menu-list{
        width: 450px;
        height: 20vh;
    }
}



 
