
.header-wrapper {
    width: 100%;
    background-color: #ddd;
}

.header-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #eee;
    flex-wrap: wrap;
}


.site-name {
    font-size: 48px;
    font-weight: bold;
    text-decoration: none;
    color: black;
    flex-shrink: 0; 
}

.site-name:hover {
    text-decoration: underline;
}


.header-navigation ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: flex-end; 
    flex-wrap: wrap;
}

.header-navigation-item {
    font-size: 24px;
    text-decoration: none;
    color: black;
    margin: 0 10px;
    background-color: greenyellow;
    padding: 16px 24px;
    border-radius: 10%;
    white-space: nowrap; 
}

.header-navigation-item:hover {
    background-color: green;
}


@media (max-width: 1024px) {
    .site-name {
        font-size: 30px;
    }

    .header-navigation ul {
        justify-content: center; 
    }
}


@media (max-width: 768px) {
    .header-main {
        flex-direction: column;
        align-items: center;
    }

    .site-name {
        font-size: 64px;
        margin: 10px 0 30px 0;
    }

    .header-navigation ul {
        flex-direction: row; 
        align-items: center;
        width: 100%; 
    }

    .header-navigation-item {
        margin: 10px 0;
        font-size: 20px;
        padding: 12px 24px;
        margin: 10px;
        width: 60%; 
        text-align: center;
    }
}


@media (max-width: 480px) {
    .site-name {
        font-size: 52px;
    }

    .header-navigation-item {
        font-size: 14px;
        padding: 10px 20px;
        
    }
}
