.baner-wrapper {
    height: 800px;
    width: 97%;
    background-image: url("../../../icons/header-main.jpg");
    background-size: cover;
    background-position-y: 65%;
    background-attachment: scroll;
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    padding: 10px;
    margin: 0 auto;
}

.baner-h2{
    font-size: 90px;
    color: white;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 25%;
    padding: 10px;
    
}

.baner-h3{
    font-size: 120px;
    color: greenyellow;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 45%;
    padding: 10px;
    
}

@media (max-width: 1024px) {
    .baner-h2{
        font-size: 74px;
        width: 100%;   
    }

    .baner-h3{
        font-size: 98px;
        width: 100%;       
    }
}

@media (max-width: 768px) {
    .baner-h2{
        font-size: 48px;
        width: 100%;   
    }

    .baner-h3{
        font-size: 72px;
        width: 100%;       
    }
  }
  
  @media (max-width: 480px) {
    .baner-h2{
        font-size: 32px;
        width: 100%;   
    }

    .baner-h3{
        font-size: 54px;
        width: 100%;       
    }
  }