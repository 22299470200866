.sterowniki-page-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.sterowniki-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
}

.sterownik-card {
    width: 45%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    padding: 20px;
}

.sterownik-image {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ddd;
}

.sterownik-card ul {
    list-style: disc;
    margin-left: 20px;
}

.back-button {
    background-color: #4CAF50; /* Zielony kolor */
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.back-button:hover {
    background-color: #45a049; /* Ciemniejszy zielony po najechaniu */
}

.sterowniki-header {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.search-bar {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.search-input {
    width: 100%;
    max-width: 400px;
    padding: 8px 12px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
    transition: border-color 0.3s;
}

.search-input:focus {
    border-color: #4CAF50; /* Zielony kolor ramki po kliknięciu */
    outline: none;
}