.menu-wrapper{
    margin: 50px 0 50px 0;
    height: auto;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;

}


.menu-item{
    display: flex;
    flex-flow: column;
    background-color: green;
    justify-content: center;
    align-items: center;
    padding: 10px;

}

.menu-item:nth-child(2n){
    background-color: yellowgreen;
}


.menu-item-description{
    text-align: center;
    padding: 10px;
    font-size: 24px;
    margin-bottom: 20px;
}

.menu-item-img{
    width: 75px;
    height: 75px;
    border-radius: 25%;
    margin-top: 20px;
}

.menu-item-title{
    font-size: 32px;
    padding: 20px 0;
    text-decoration: none;
    color: white;
    text-transform: uppercase;
    position:relative;
    transition: 0.3s;
}

.menu-item-title:hover{
    color: yellow;
}


@media (max-width: 1024px) {
    .menu-wrapper{
        height: auto;
    }

    .menu-item-description{
        text-align: center;
        padding: 20px;
        font-size: 20px;
    }
    
    .menu-item-img{
        width: 75px;
        height: 75px;
        border-radius: 25%;
    }
    
    .menu-item-title{
        font-size: 22px;
    }
    
}

@media (max-width: 768px) {
    .menu-wrapper{
        height: auto;
    }
    
    .menu-item-description{
        text-align: center;
        padding: 20px;
        font-size: 18px;
    }
    
    .menu-item-img{
        width: 75px;
        height: 75px;
        border-radius: 25%;
    }
    
    .menu-item-title{
        font-size: 16px;
    }
  }
  
  @media (max-width: 480px) {
    .menu-wrapper{
        flex-direction: column;
        height: auto;
    }
    
  }