.zraszacze-page-container {
  padding: 20px;
  box-sizing: border-box;
  min-height: calc(100vh - 80px); /* Dopasowanie wysokości, uwzględniając wysokość stopki */
}

.zraszacz-card {
  height: auto;
  max-height: 45vh; /* Maksymalna wysokość karty */
  overflow-y: auto; /* Włączenie przewijania pionowego */
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 25px 10px; /* Odstęp między kartami */
}

.zraszacz-image {
  width: 100%;
  max-height: 200px; /* Ograniczenie wysokości obrazka */
  object-fit: contain;
  margin-bottom: 10px;
}

.nozzle-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.nozzle-table th,
.nozzle-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 0.9rem;
}
