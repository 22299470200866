@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Monoton:wght@200;300;400;500;600;700;800&display=swap');

.project-page-side-menu{
margin:0;
padding:0;
border: none;
outline: none;
box-sizing: border-box;
font-family: 'Poppins', sans-serif;
z-index: 5;
width: 300px;
background-color: #001529;
height: auto;
}

.project-page-side-menu-sidebar{
    color: #fff;
}


.sprinkler-setting-main{
    position: absolute;
    bottom: 100px;
    left: 0;
}


@media (max-width: 768px) {
    .project-page-side-menu{
        width: 100%;
    }
}


