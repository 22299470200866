footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #ddd;
    font-size: 20px;
    flex-wrap: wrap;
    z-index: 100;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.footer-contact {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* Pozwala na zawijanie wierszy w przypadku małych ekranów */
}

.footer-item {
    display: flex;
    align-items: center;
    margin-right: 20px; /* Odstęp między elementami kontaktu */
}

.footer-icon {
    margin-right: 10px; /* Odstęp między ikoną a tekstem */
}

.footer-text {
    font-family: Arial, Helvetica, sans-serif;
}

.footer-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer-icon-link img {
    width: 40px;
    height: 40px;
    margin-right: 10px; /* Odstęp między ikonami */
    transition: 0.5s;
}

.footer-icon-link img:hover {
    opacity: 50%;
}

/* RESPONSYWNOŚĆ */

/* Dla ekranów o szerokości do 1024px */
@media (max-width: 1024px) {
    footer {
        padding: 10px;
        flex-direction: column; /* Stopka przechodzi do układu kolumnowego */
        align-items: center;
    }

    .footer-contact {
        justify-content: center;
        margin-bottom: 10px; /* Odstęp między sekcją kontaktu a ikonami */
    }

    .footer-icons {
        justify-content: center; /* Ikony są wyśrodkowane */
    }

    .footer-icon-link img {
        width: 20px;
        height: 20px;

    }
}

/* Dla ekranów o szerokości do 768px */
@media (max-width: 768px) {
    .footer-contact {
        flex-direction: column; /* Kontakty układają się w kolumnie */
        align-items: center;
    }

    .footer-item {
        margin-right: 0;
        margin-bottom: 10px; /* Odstęp między kontaktami w układzie kolumnowym */
    }

    .footer-icons {
        justify-content: center;
    }
}

/* Dla ekranów o szerokości do 480px */
@media (max-width: 480px) {
    .footer-icon-link img {
        width: 30px;
        height: 30px; /* Zmniejszenie ikon na bardzo małych ekranach */
    }

    footer {
        font-size: 18px;
    }

    .footer-item {
        margin-bottom: 5px; /* Mniejszy odstęp między kontaktami */
    }
}
