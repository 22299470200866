
.project-stage {
    width: 100%; 
    height: 100%; 
    overflow: hidden;
    position: relative;
  }

  .submit-link-main{
    bottom: 10vh;
    left: 350px;
    background-color: green;
    width: 90px;
    height: 90px;
  }


  @media (max-width: 768px){
    .submit-link-main{
      bottom: 30vw;
      right: 5vw;
      left:auto;
    }
  }