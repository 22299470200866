.source-modal-main p{
    font-size: 24px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

.source-modal-input{
    margin: 10px;
}

.instruction-img{
    width: 100%;
    height: 100%;

}
.step-content-main{
    
    margin-top: 20px;
}

.source-modal-btn {
    display: flex;
    justify-content: flex-end; /* Przesunięcie przycisków na prawą stronę */
    gap: 10px; /* Odstęp między przyciskami */
}

.step-button-prev {
    background-color: #f0f0f0;
    color: #333;
}

.step-button-next {
    background-color: #1890ff;
    color: white;
}
