.help-navigation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: arial;
    padding: 20px;
    margin: 0 auto;
    max-width: 1440px;
}

.help-navigation-title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
}

.help-navigation-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px;

}
.help-navigation-img {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
    margin: 30px 0;
}

.help-navigation-details {
    font-size: 1rem;
    line-height: 1.6;
    color: #444;
    text-align: left;
    max-width: 900px;
}

.help-navigation-details h2 {
    font-size: 1.6rem;
    margin: 20px 0;
    color: green;
}

.help-navigation-details h3 {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: #333;
}

.help-navigation-details p {
    font-size: 20px;
    margin-bottom: 10px;
}

.help-navigation-details ol {
    list-style: decimal inside;
    margin-left: 20px;
}

.help-navigation-details li {
    margin-bottom: 15px;
    font-size: 20px;
}

.help-navigation-details strong {
    color: #0073e6; /* Kolor dla wyróżnienia kluczowych elementów */
}

.help-navigation-footer {
    margin-top: 20px;
    padding: 20px;
    background-color: #e8f5e9;
    border-radius: 8px;
    color: #388e3c;
    font-size: 1.1rem;
    font-weight: 500;
    text-align: center;
    width: 100%;
    max-width: 900px;
}
.help-navigation-footer-warning{
    margin-top: 20px;
    padding: 20px;
    background-color: #fabdbd;
    border-radius: 8px;
    color: rgb(255, 0, 0);
    font-size: 1.1rem;
    font-weight: 500;
    text-align: center;
    width: 100%;
    max-width: 900px;
}
